<template>
  <v-card :loading="loadingModel" fill-height flat class="wl-page-module">
    <v-card-title>Funds</v-card-title>
    <v-card-text v-if="loadingModel">
      <v-skeleton-loader type="table-tbody" />
    </v-card-text>
    <v-card-text v-else>
      <v-data-table :headers="headers" :items="funds"></v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { ModelFundColumns } from './lib/columns'
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      model: 'model/getModel',
      loadingModel: 'model/getLoadingModel',
    }),
  },
  watch: {
    model() {
      this.setFunds()
    },
  },
  data() {
    return {
      funds: [],
      headers: ModelFundColumns,
    }
  },
  methods: {
    setFunds() {
      this.funds = this.model?.funds || []
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';
</style>
