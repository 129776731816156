<template>
  <v-card :loading="loadingModel" fill-height flat class="page-module">
    <v-skeleton-loader v-show="loadingModel" type="header" />
    <v-card-title v-show="!loadingModel">
      Preview Changes
      <v-spacer />

      <v-alert dense outlined type="error" v-if="isEarlyRebalance">
        It is recommended that you
        <strong style="text-decoration: underline;">not</strong>
        make model updates more than once a day!
      </v-alert>
      <v-progress-circular
        v-else
        width="4"
        :color="modelWeight !== 100 ? 'error' : 'bobGreen'"
        :value="modelWeight"
        :rotate="360"
        :size="65"
        small
      >
        {{ modelWeight }}%
      </v-progress-circular>
      <v-spacer />
      <v-menu offset-y bottom left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn fab v-bind="attrs" v-on="on" icon :disabled="loadingModel">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item>
            <v-list-item-title>
              <v-btn
                outlined
                color="bobGreen"
                @click="() => cancelPreviewChanges()"
              >
                <v-icon>mdi-close-circle-outline</v-icon>
                Leave Preview
              </v-btn>
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title>
              <v-btn
                :disabled="!validModelWeight || !modelChanged"
                outlined
                color="bobGreen"
                @click="() => updateModelWeights(model)"
              >
                <v-icon>mdi-content-save-settings-outline</v-icon>
                Confirm Changes
              </v-btn>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-card-title>
    <v-skeleton-loader v-show="loadingModel" type="table" />
    <v-card-title v-if="!loadingModel">New Symbols</v-card-title>
    <v-card-text v-if="!loadingModel">
      <v-data-table
        :headers="newSymbolHeaders"
        :items="newSymbols"
        :calculate-widths="true"
      >
        <template v-slot:item.symbol="{ item }">
          <h3>{{ item.symbol }}</h3>
        </template>
        <template v-slot:item.allocation="{ item }">
          <h2>{{ formatPercent(item.allocation || 0) }} %</h2>
        </template>
      </v-data-table>
    </v-card-text>
    <v-card-title v-if="!loadingModel">Updated Symbols</v-card-title>
    <v-card-text v-if="!loadingModel">
      <v-data-table
        :headers="changedSymbolHeaders"
        :items="changedSymbols"
        :calculate-widths="true"
      >
        <template v-slot:item.symbol="{ item }">
          <h3>{{ item.symbol }}</h3>
        </template>
        <template v-slot:item.oldAllocation="{ item }">
          <h2>{{ formatPercent(item.oldAllocation || 0) }} %</h2>
        </template>
        <template v-slot:item.allocation="{ item }">
          <h2>{{ formatPercent(item.allocation || 0) }} %</h2>
        </template>
        <template v-slot:item.exitOnly="{ item }">
          <v-switch
            v-model="item.exitOnly"
            @change="
              () =>
                updateTickerInModel({
                  ...item,
                  allocationPercent: item.allocation * 100,
                })
            "
          />
        </template>
        <template v-slot:item.roundUpShares="{ item }">
          <v-switch
            v-model="item.roundUpShares"
            @change="
              () =>
                updateTickerInModel({
                  ...item,
                  allocationPercent: item.allocation * 100,
                })
            "
          />
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { formatPercent } from '@/utils/number'
import { mapActions, mapGetters } from 'vuex'
export default {
  data() {
    return {
      formatPercent,
      symbols: [],
      showSymbolAllocationDialog: false,
      showAddSymbolDialog: false,
      activeSymbol: undefined,
      newSymbol: undefined,
      newSymbolHeaders: [
        {
          text: 'Symbol',
          value: 'symbol',
          width: '20%',
        },
        {
          text: 'Allocation',
          value: 'allocation',
        },
      ],
      changedSymbolHeaders: [
        {
          text: 'Symbol',
          value: 'symbol',
          width: '15%',
        },
        {
          text: 'Previous Allocation',
          value: 'oldAllocation',
          width: '15%',
        },
        {
          text: 'New Allocation',
          value: 'allocation',
        },
        {
          text: 'Exit Only',
          value: 'exitOnly',
          width: '15%',
          sortable: false,
        },
        {
          text: 'Round Up Shares',
          value: 'roundUpShares',
          width: '15%',
          sortable: false,
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      model: 'model/getModel',
      loadingModel: 'model/getLoadingModel',
      symbolData: 'symbol/getSymbols',
      modelWeight: 'model/getModelWeight',
      modelChanged: 'model/getModelChanged',
      rebalanceModelPreview: 'model/getRebalanceModelPreview',
    }),
    validModelWeight() {
      return this.modelWeight === 100
    },
    newSymbols() {
      return this.rebalanceModelPreview?.newSymbols || []
    },
    changedSymbols() {
      return (this.rebalanceModelPreview?.changedSymbols || []).map((s) => ({
        ...s,
        exitOnly: false,
        roundUpShares: false,
      }))
    },
    isEarlyRebalance() {
      const rebalanceDate = new Date(this.model?.lastRebalanceRequest)
      const today = new Date()
      const timeDiff = today.getTime() - rebalanceDate.getTime()
      const daysDiff = timeDiff / (1000 * 3600 * 24)
      return daysDiff < 1 // <<-- less than one day.
    },
  },
  watch: {
    model() {
      this.setSymbols()
    },
  },
  async mounted() {
    this.setSymbols()
  },
  methods: {
    ...mapActions({
      addTickerToModel: 'model/addTickerToModel',
      updateTickerInModel: 'model/updateTickerInModel',
      updateModelWeights: 'model/updateModelWeights',
      previewModelWeightUpdates: 'model/previewModelWeightUpdates',
      cancelPreviewChanges: 'model/cancelPreviewChanges',
    }),
    setSymbols() {
      this.symbols = this.model?.symbols || []
    },
    toggleSymbolAllocationDialog(symbolObject) {
      this.showSymbolAllocationDialog = true
      this.activeSymbol = symbolObject
      this.activeSymbol.allocationPercent = formatPercent(
        symbolObject.allocation
      )
    },
    updateSymbolAllocation() {
      this.updateTickerInModel(this.activeSymbol)
      this.activeSymbol = undefined
      this.showSymbolAllocationDialog = false
    },
    addSymbol() {
      this.addTickerToModel({ symbol: this.newSymbol, allocation: 0 })
      this.newSymbol = undefined
      this.showAddSymbolDialog = false
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';

.symbol-label {
  margin-left: 20px;
  font-weight: 600;
  font-size: 22px;
}
.page-module {
  border: 1px solid black;
  border-radius: 10px;
  margin: 10px;
}
.allocation-input {
  width: 100%;
}
.v-input::v-deep {
  height: 30px !important;
  width: 100%;
}
.allocation-card {
  width: 100%;
}

.add-symbol-actions {
  margin-right: 16px;
}
</style>
