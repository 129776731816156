<template>
  <v-card
    :loading="loadingModelClients"
    fill-height
    flat
    class="wl-page-module"
  >
    <v-card-title>
      Clients
    </v-card-title>
    <v-card-text v-if="loadingModelClients">
      <v-skeleton-loader type="table-tbody" />
    </v-card-text>
    <v-card-text v-if="!loadingModelClients">
      <v-text-field
        label="Search Clients"
        v-model="searchClientsValue"
        outlined
        color="bobGreen"
        @keydown="initSearch"
      />
      <v-data-table
        :headers="headers"
        :items="modelClients || []"
        :search="searchClients"
        :page="page"
        @pagination="($event) => (page = $event.page)"
        @click:row="handleRowClick"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { ModelClientColumns } from './lib/columns'
export default {
  props: {
    modelId: { type: String, default: undefined },
  },
  data() {
    return {
      headers: ModelClientColumns,
      searchClients: undefined,
      searchClientsValue: undefined,
      page: 1,
    }
  },
  computed: {
    ...mapGetters({
      model: 'model/getModel',
      modelClients: 'client/getModelClients',
      loadingModelClients: 'client/getLoadingModelClients',
      symbolData: 'symbol/getSymbols',
      modelWeight: 'model/getModelWeight',
    }),
  },
  watch: {
    async modelId(modelId) {
      await this.getModelClients(modelId)
    },
  },
  async mounted() {
    await this.getModelClients(this.modelId)
  },
  methods: {
    ...mapActions({
      getModelClients: 'client/getModelClients',
      updateTickerInModel: 'model/updateTickerInModel',
    }),
    initSearch($event) {
      this.debounce = setTimeout(() => {
        this.searchClients = this.searchClientsValue
        this.page = 1
      }, 1000)
      if ($event.key === 'Enter') {
        this.searchClients = this.searchClientsValue
        this.page = 1
        clearTimeout(this.debounce)
      }
    },
    async handleRowClick(row) {
      await this.$router.push(`/advisor/clients/${row?._id}`).catch((e) => e)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';
</style>
