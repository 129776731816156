var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"page-module",attrs:{"loading":_vm.loadingModel,"fill-height":"","flat":""}},[_c('v-skeleton-loader',{directives:[{name:"show",rawName:"v-show",value:(_vm.loadingModel),expression:"loadingModel"}],attrs:{"type":"header"}}),_c('v-card-title',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loadingModel),expression:"!loadingModel"}]},[_vm._v(" Preview Changes "),_c('v-spacer'),(_vm.isEarlyRebalance)?_c('v-alert',{attrs:{"dense":"","outlined":"","type":"error"}},[_vm._v(" It is recommended that you "),_c('strong',{staticStyle:{"text-decoration":"underline"}},[_vm._v("not")]),_vm._v(" make model updates more than once a day! ")]):_c('v-progress-circular',{attrs:{"width":"4","color":_vm.modelWeight !== 100 ? 'error' : 'bobGreen',"value":_vm.modelWeight,"rotate":360,"size":65,"small":""}},[_vm._v(" "+_vm._s(_vm.modelWeight)+"% ")]),_c('v-spacer'),_c('v-menu',{attrs:{"offset-y":"","bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","icon":"","disabled":_vm.loadingModel}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c('v-list',[_c('v-list-item',[_c('v-list-item-title',[_c('v-btn',{attrs:{"outlined":"","color":"bobGreen"},on:{"click":function () { return _vm.cancelPreviewChanges(); }}},[_c('v-icon',[_vm._v("mdi-close-circle-outline")]),_vm._v(" Leave Preview ")],1)],1)],1),_c('v-list-item',[_c('v-list-item-title',[_c('v-btn',{attrs:{"disabled":!_vm.validModelWeight || !_vm.modelChanged,"outlined":"","color":"bobGreen"},on:{"click":function () { return _vm.updateModelWeights(_vm.model); }}},[_c('v-icon',[_vm._v("mdi-content-save-settings-outline")]),_vm._v(" Confirm Changes ")],1)],1)],1)],1)],1)],1),_c('v-skeleton-loader',{directives:[{name:"show",rawName:"v-show",value:(_vm.loadingModel),expression:"loadingModel"}],attrs:{"type":"table"}}),(!_vm.loadingModel)?_c('v-card-title',[_vm._v("New Symbols")]):_vm._e(),(!_vm.loadingModel)?_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.newSymbolHeaders,"items":_vm.newSymbols,"calculate-widths":true},scopedSlots:_vm._u([{key:"item.symbol",fn:function(ref){
var item = ref.item;
return [_c('h3',[_vm._v(_vm._s(item.symbol))])]}},{key:"item.allocation",fn:function(ref){
var item = ref.item;
return [_c('h2',[_vm._v(_vm._s(_vm.formatPercent(item.allocation || 0))+" %")])]}}],null,false,2915823823)})],1):_vm._e(),(!_vm.loadingModel)?_c('v-card-title',[_vm._v("Updated Symbols")]):_vm._e(),(!_vm.loadingModel)?_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.changedSymbolHeaders,"items":_vm.changedSymbols,"calculate-widths":true},scopedSlots:_vm._u([{key:"item.symbol",fn:function(ref){
var item = ref.item;
return [_c('h3',[_vm._v(_vm._s(item.symbol))])]}},{key:"item.oldAllocation",fn:function(ref){
var item = ref.item;
return [_c('h2',[_vm._v(_vm._s(_vm.formatPercent(item.oldAllocation || 0))+" %")])]}},{key:"item.allocation",fn:function(ref){
var item = ref.item;
return [_c('h2',[_vm._v(_vm._s(_vm.formatPercent(item.allocation || 0))+" %")])]}},{key:"item.exitOnly",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{on:{"change":function () { return _vm.updateTickerInModel(Object.assign({}, item,
                {allocationPercent: item.allocation * 100})); }},model:{value:(item.exitOnly),callback:function ($$v) {_vm.$set(item, "exitOnly", $$v)},expression:"item.exitOnly"}})]}},{key:"item.roundUpShares",fn:function(ref){
              var item = ref.item;
return [_c('v-switch',{on:{"change":function () { return _vm.updateTickerInModel(Object.assign({}, item,
                {allocationPercent: item.allocation * 100})); }},model:{value:(item.roundUpShares),callback:function ($$v) {_vm.$set(item, "roundUpShares", $$v)},expression:"item.roundUpShares"}})]}}],null,false,888174275)})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }